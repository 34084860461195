import '/index.scss';
import AudioPlayer from './ts/modules/audio-player';
import AudioLibrary from './ts/modules/audio-library';
import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupA11yPlugin from '@swup/a11y-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';

const swup = new Swup({
  containers: [
    '#main',
    '#page-logo',
    ],
  animateHistoryBrowsing: true,
  plugins: [
    new SwupBodyClassPlugin({
      prefix: 'template-'
    }),
    new SwupA11yPlugin(),
    new SwupHeadPlugin({
      persistAssets: true //vite!
    }),
    //
    new SwupProgressPlugin(),
    new SwupScrollPlugin({
      offset: 100,
      doScrollingRightAway: true,
      animateScroll: {
        betweenPages: true,
        samePageWithHash: false,
        samePage: false
      }
    })
  ],
});

const audioPlayer = new AudioPlayer();
let audioLibrary: AudioLibrary | null;

console.info('Hi! 👋');

function init() {
  audioLibrary = new AudioLibrary(audioPlayer);
}

function unload() {
  audioLibrary?.destroy();
}

// run once when page loads
if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => init());
}
swup.hooks.on('page:view', () => init());
swup.hooks.before('content:replace', () => unload());