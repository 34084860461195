import Plyr from "plyr";

interface AudioTrack {
  [key:number]: number
  uuid: string,
  url: string,
  slug: string,
  title: string,
  artist: string,
  artwork: string
}

export default class AudioPlayer {

  currentIndex = 0;
  currentTrack: AudioTrack | null;
  tracks: Array<AudioTrack> = [];
  plyr: Plyr;
  lastScrollTop: number;

  headerWidgetLink: HTMLAnchorElement | null;

  scrollHandler: EventListener;

  constructor() {

    this.lastScrollTop = window.scrollY || document.documentElement.scrollTop;

    if('tracks' in window) {
      this.tracks = window["tracks"] as Array<AudioTrack>;
    }

    if(this.tracks.length == 0) {
      throw new Error("No tracks found");
    }

    this.plyr = new Plyr('#audio-player', {
      controls: controls,
      tooltips: {
        controls: false
      },
      mediaMetadata: {
        artist: "Jim Krutor",
      }
    });

    const headerWidgetLink = document.getElementById('audio-player-header-title');
    if(headerWidgetLink instanceof HTMLAnchorElement) {
      this.headerWidgetLink = headerWidgetLink;
    }

    if(this.tracks[this.currentIndex]) {
      this.loadTrack(this.tracks[this.currentIndex]);
    }

    this.plyr.on('play', () => {
      document.body.classList.add('audio-player-playing');
      document.getElementById('plyr__control-play-external')?.classList.toggle('plyr__control--pressed', this.plyr.playing);
      if((window as any).plausible) {
        (window as any).plausible('Play', {props: {title: this.currentTrack?.title}});
      }
    })
    this.plyr.on('pause', () => {
      document.body.classList.remove('audio-player-playing');
      document.getElementById('plyr__control-play-external')?.classList.toggle('plyr__control--pressed', this.plyr.playing);
    })

    this.plyr.on('ended', () => {
      this.next(true);
      if((window as any).plausible) {
        (window as any).plausible('Ended', {props: {title: this.currentTrack?.title}});
      }
    })

    document.addEventListener('click', (event)=> {

      if(event.target instanceof HTMLElement) {
        switch (event.target.dataset.plyr) {
          case 'next':
            this.next();
            break;

          case 'prev':
            this.prev();
            break;

          case 'play-external':
            this.plyr.togglePlay();
            break;
        }
      }
    })

    this.scrollHandler = this.handleScrollEvent.bind(this);
    document.addEventListener('scroll', this.scrollHandler, false);
  }

  handleScrollEvent(e: Event) {

    var st = window.scrollY || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > this.lastScrollTop) {
      // downscroll code
      document.body.classList.add('scroll-down');
    } else if (st < this.lastScrollTop) {
      // upscroll code
      document.body.classList.remove('scroll-down');
    } // else was horizontal scroll
    this.lastScrollTop = Math.max(0, st); // For Mobile or negative scrolling

    // const threshold = 0;
    // let currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // let amount = currentScrollTop - this.lastScrollTop;
    
    // if(
    //   currentScrollTop <= 0 //we are on top
    //   ||
    //   amount < (threshold * -1)
    // ) {
    //   this.lastScrollTop = currentScrollTop;
    //   document.body.classList.remove('scrolled-down');
    // } else if(amount > threshold) {
    //   this.lastScrollTop = currentScrollTop;
    //   document.body.classList.add('scrolled-down');
    // }
  }

  loadTrackByUuid(uuid: string | null = null, resumePlayback: boolean = this.plyr.playing) {
    if(uuid) {

      const newTrack = this.tracks.find((track) => track.uuid == uuid);

      if(newTrack) {
        this.loadTrack(newTrack, resumePlayback);
      }
    }
  }

  loadTrack(track: AudioTrack, resumePlayback: boolean = this.plyr.playing) {

    if(track != this.currentTrack) {

      document.getElementById('plyr-artwork')?.classList.add('hide');

      this.plyr.source = {
        type: 'audio',
        title: track.title,
        sources: [{
          src: track.url,
          type: 'audio/mpeg' 
        }]
      }
      
      this.headerWidgetLink!.textContent = track.title;
      this.headerWidgetLink!.href = '/en/jim-krutor#' + track.slug;
      this.updateArtwork(track.artwork);
      this.updateMetadata(track);
      this.currentIndex = this.tracks.indexOf(track);
      this.currentTrack = track;
      this.plyr.togglePlay(resumePlayback);
    }
  }

  updateArtwork(url: string = "") {
    const artworkElement = document.getElementById('plyr-artwork');
    if(artworkElement instanceof HTMLImageElement) {
      
      //plyr rerenders everything
      artworkElement.addEventListener('load', (e) => {
        const target = e.target as HTMLImageElement;
        target.classList.remove('hide');
      })
      artworkElement.src = url;
      
    }
  }

  updateMetadata(track: AudioTrack) {

    if ("mediaSession" in navigator) {

      const metadata = {
        title: track.title,
        artist: track.artist,
        artwork: [
          {
            src: track.artwork,
            sizes: "1080x1080",
            type: "image/jpeg",
          },
        ],
      };
      navigator.mediaSession.metadata = new MediaMetadata(metadata);
    }
  }

  next(resumePlayback: boolean = this.plyr.playing) {
    let nextTrack = this.tracks[this.currentIndex + 1] ?? this.tracks[0] ?? null;
    if(nextTrack) {
      this.loadTrack(nextTrack, resumePlayback);
    }
  }

  prev(resumePlayback: boolean = this.plyr.playing) {
    let prevTrack = this.tracks[this.currentIndex - 1] ?? this.tracks[this.tracks.length - 1] ?? null;
    if(prevTrack) {
      this.loadTrack(prevTrack, resumePlayback);
    }
  }

  getCurrentTrack(): AudioTrack | null  {
    return this.tracks[this.currentIndex] ?? null;
  }
}

const controls = `
<div class="plyr__controls">

  <section class="section-info">
    <img id="plyr-artwork" width="300" height="300" class="hide" src="" role="presentation"/>
    <p>{title}</p>
  </section>

  <section class="section-playback-controls">
    
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="next">
        <svg role="presentation" width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5615866,8.10002147 L3.87056367,0.225209313 C3.05219207,-0.33727727 2,0.225209313 2,1.12518784 L2,16.8748122 C2,17.7747907 3.05219207,18.3372773 3.87056367,17.7747907 L15.5615866,9.89997853 C16.1461378,9.44998927 16.1461378,8.55001073 15.5615866,8.10002147 L15.5615866,8.10002147 Z"/>
          <rect x="15" y="0" width="3" height="18"/>
        </svg>
        <span class="plyr__tooltip" role="tooltip">Next</span>
    </button>
    <button type="button" class="plyr__control" data-plyr="prev">
        <svg role="presentation" width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" style="transform:scaleX(-1)">
          <path d="M15.5615866,8.10002147 L3.87056367,0.225209313 C3.05219207,-0.33727727 2,0.225209313 2,1.12518784 L2,16.8748122 C2,17.7747907 3.05219207,18.3372773 3.87056367,17.7747907 L15.5615866,9.89997853 C16.1461378,9.44998927 16.1461378,8.55001073 15.5615866,8.10002147 L15.5615866,8.10002147 Z"/>
          <rect x="15" y="0" width="3" height="18"/>
        </svg>
        <span class="plyr__tooltip" role="tooltip">Previous</span>
    </button>
  </section>

  <section class="section-progress">
    <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek"/>
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>
    <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
  </section>

  <section class="section-volume">
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
    </button>
    <div class="plyr__volume">
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume"/>
    </div>
  </section>
</div>
`;