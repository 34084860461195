import AudioPlayer from "./audio-player";

export default class AudioLibrary {

  audioPlayer: AudioPlayer;
  buttons: NodeListOf<HTMLButtonElement>;

  //document events
  clickHandler: EventListener;

  //plyr events
  playHandler: EventListener;
  pauseHandler: EventListener;
  loadstartHandler: EventListener;

  public constructor(audioPlayer: AudioPlayer) {

    this.audioPlayer = audioPlayer;
    this.buttons = document.querySelectorAll('button.audio-player-play');

    const currentTrack = this.audioPlayer.getCurrentTrack();
    
    if(currentTrack) {
      this.activateButtons(currentTrack.uuid);
    }

    //document events
    this.clickHandler = this.handleClickEvent.bind(this);

    //plyr events
    this.playHandler = this.handlePlayEvent.bind(this);
    this.pauseHandler = this.handlePauseEvent.bind(this);
    this.loadstartHandler = this.handleLoadstartEvent.bind(this);

    this.enable();
  }

  enable() {
    document.addEventListener('click', this.clickHandler, false);
    this.audioPlayer.plyr.on('play', this.playHandler);
    this.audioPlayer.plyr.on('pause', this.pauseHandler);
    this.audioPlayer.plyr.on('loadstart', this.loadstartHandler);
    this.buttons.forEach(button => button.classList.remove('not-active'));
  }

  destroy() {
    document.removeEventListener('click', this.clickHandler);
    this.audioPlayer.plyr.off('play', this.playHandler);
    this.audioPlayer.plyr.off('pause', this.pauseHandler);
    this.audioPlayer.plyr.off('loadstart', this.loadstartHandler);
    this.buttons.forEach(button => button.classList.add('not-active'));
  }

  handlePlayEvent(e: Plyr.PlyrEvent) {
    const currentTrack = this.audioPlayer.getCurrentTrack();
    if(currentTrack) {
      this.activateButtons(currentTrack.uuid);
    }
  }

  handlePauseEvent(e: Plyr.PlyrEvent) {
    this.activateButtons(null);
  }

  handleLoadstartEvent(e: Plyr.PlyrEvent) {
    this.activateButtons(null);
    const currentTrack = this.audioPlayer.getCurrentTrack();
    if(currentTrack) {
      this.activateButtons(currentTrack.uuid);
    }
  }

  handleClickEvent(e: MouseEvent) {
    
    const target = e.target as HTMLElement;

    if(target.classList.contains('audio-player-play')) {

      const newUuid = target.dataset.uuid;
      const currentUuid = this.audioPlayer.getCurrentTrack()?.uuid;

      if(newUuid == currentUuid) {

        this.audioPlayer.plyr.togglePlay()

      } else {

        this.audioPlayer.loadTrackByUuid(newUuid, true); //diferent media - load and play
      }
    }
  };

  activateButtons(uuid: string | null) {
    
    this.buttons.forEach((button) => {
      button.classList.remove('is-active')
    });

    if(uuid) {
      document.querySelectorAll('[data-uuid="' + uuid + '"]').forEach(button => {
        button.classList.toggle('is-active', this.audioPlayer.plyr.playing);
      })
    }
  }
}